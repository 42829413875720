import {
    faFacebook as fabFacebook,
    faXTwitter as fabXTwitter,
    faLinkedin as fabLinkedin,
    faInstagram as fabInstagram,
} from '@fortawesome/free-brands-svg-icons'

import {
} from '@fortawesome/pro-light-svg-icons'

import {
    faAngleDown as farAngleDown,
    faAngleUp as farAngleUp,
    faArrowLeft as farArrowLeft,
    faArrowProgress as farArrowProgress,
    faArrowRight as farArrowRight,
    faArrowUpRightFromSquare as farArrowUpRightFromSquare,
    faAt as farAt,
    faBars as farBars,
    faBrush as farBrush,
    faBuilding as farBuilding,
    faBullhorn as farBullhorn,
    faCalendar as farCalendar,
    faCartShoppingFast as farCartShoppingFast,
    faCheck as farCheck,
    faCheckDouble as farCheckDouble,
    faCircle as farCircle,
    faCircleInfo as farCircleInfo,
    faCity as farCity,
    faClock as farClock,
    faCloudBinary as farCloudBinary,
    faComment as farComment,
    faDesktop as farDesktop,
    faEnvelope as farEnvelope,
    faFileCsv as farFileCsv,
    faIdCard as farIdCard,
    faImageSlash as farImageSlash,
    faInfoCircle as farInfoCircle,
    faInputNumeric as farInputNumeric,
    faList as farList,
    faLock as farLock,
    faMapLocation as farMapLocation,
    faMapMarker as farMapMarker,
    faMapPin as farMapPin,
    faMinus as farMinus,
    faMoneyBill as farMoneyBill,
    faPen as farPen,
    faPhone as farPhone,
    faPlus as farPlus,
    faRectangleHistory as farRectangleHistory,
    faRoad as farRoad,
    faScreenUsers as farScreenUsers,
    faSearch as farSearch,
    faSignIn as farSignIn,
    faSignOut as farSignOut,
    faSliders as farSliders,
    faSpinnerThird as farSpinnerThird,
    faSquarePhone as farSquarePhone,
    faSquareSliders as farSquareSliders,
    faTicket as farTicket,
    faTimes as farTimes,
    faToggleOn as farToggleOn,
    faTrash as farTrash,
    faUser as farUser,
    faUsers as farUsers,
    faXmark as farXmark,
} from '@fortawesome/pro-regular-svg-icons'

import {
} from '@fortawesome/pro-solid-svg-icons'

export const icons = {
    farAngleDown,
    farAngleUp,
    farArrowLeft,
    farArrowProgress,
    farArrowRight,
    farArrowUpRightFromSquare,
    farAt,
    farBars,
    farBrush,
    farBuilding,
    farBullhorn,
    farCalendar,
    farCartShoppingFast,
    farCheck,
    farCheckDouble,
    farCircle,
    farCircleInfo,
    farCity,
    farClock,
    farCloudBinary,
    farComment,
    farDesktop,
    farEnvelope,
    farFileCsv,
    farIdCard,
    farImageSlash,
    farInfoCircle,
    farInputNumeric,
    farList,
    farLock,
    farMapLocation,
    farMapMarker,
    farMapPin,
    farMinus,
    farMoneyBill,
    farPen,
    farPhone,
    farPlus,
    farRectangleHistory,
    farRoad,
    farScreenUsers,
    farSearch,
    farSignIn,
    farSignOut,
    farSliders,
    farSpinnerThird,
    farSquarePhone,
    farSquareSliders,
    farTicket,
    farTimes,
    farToggleOn,
    farTrash,
    farUser,
    farUsers,
    farXmark,

    fabFacebook,
    fabXTwitter,
    fabLinkedin,
    fabInstagram,
}