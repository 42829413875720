import Glide from '@glidejs/glide'
import AirDatepicker from 'air-datepicker';
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import localeEn from 'air-datepicker/locale/en';

/**
 * Sentry
 */
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://3df6dd288c493a5d069908321668b1c7@o4508358341033984.ingest.de.sentry.io/4508358342869072",
    environment: process.env.NODE_ENV || 'development',
    integrations: [
        Sentry.replayIntegration(),
        Sentry.feedbackIntegration({
            // Additional SDK configuration goes in here, for example:
            colorScheme: "light",
            autoInject: window.showFeedback ?? false,
        }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/**
 * Font Awesome
*/
import { icons } from './icons.js'
import { library, dom } from '@fortawesome/fontawesome-svg-core'

library.add(icons);

dom.watch();

/**
 * Set's the initial date range for the search bar
 */

const searchBar = document.getElementById('search_bar');

if (searchBar) {

    const searchDatePicker = searchBar.querySelector('.datepicker');

    let startDate = searchDatePicker.dataset.startDate;
    let endDate = searchDatePicker.dataset.endDate;

    new AirDatepicker('#search_bar .datepicker', {
        selectedDates: [startDate, endDate],
        locale: localeEn,
        range: true,
        dateFormat: 'd MMM yyyy',
        multipleDatesSeparator: '  -  ',
        minDate: new Date(),
    });

    /**
     * Prevent the search bar form from submitting if the enter key is pressed
     */
    searchBar.addEventListener('keypress', function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    });
}


/**
 * Alpine.js
 */
window.Alpine = Alpine
Alpine.plugin(collapse)
Alpine.start()

/**
 * Hero Carousels
 */
document.querySelectorAll('[data-glide="hero"]').forEach((element) => {
    new Glide(element, {
        type: 'carousel',
        perView: 1,
        focusAt: 'center',
        gap: 0,
        autoplay: false,
        hoverpause: true,
        animationDuration: 1000,
    }).mount()
});

document.querySelectorAll('[data-glide="course-dates"]').forEach((element) => {
    let numSlides = element.querySelectorAll('.glide__slide').length;
    let perView = Math.min(5, numSlides);

    let breakpoints = {
        1920: {
            perView: Math.min(4, numSlides),
        },
        1440: {
            perView: Math.min(3, numSlides),
        },
        1080: {
            perView: Math.min(2, numSlides),
        },
        720: {
            perView: 1,
        }
    }

    new Glide(element, {
        type: 'slider',
        bound: true,
        startAt: 0,
        focusAt: 0,
        gap: 0,
        perView: perView,
        autoplay: false,
        breakpoints: breakpoints,
        rewindDuration: 0
    }).mount()
});

/**
 * Allows us to utilize the range input's progress value in CSS
 */
for (let e of document.querySelectorAll('input[type="range"].slider-progress')) {
    setRangeSliderProgress(e);
}

/**
 * Set the value of the input field to match the related range slider value
 */
document.querySelectorAll('input[data-price-input]').forEach(function (input) {
    input.addEventListener('input', function () {
        var value = this.value;
        var target = document.querySelector('[data-price-value="' + this.dataset.priceInput + '"]');
        if (target) {
            target.value = value;
        }
    });
});

/**
 * Set the value of the range slider to match the related input value
 */
document.querySelectorAll('input[data-price-value]').forEach(function (input) {
    input.addEventListener('input', function () {
        var value = this.value;
        var target = document.querySelector('[data-price-input="' + this.dataset.priceValue + '"]');

        if (target) {
            target.value = value;
            setRangeSliderProgress(target);
        }
    });
});

/**
 * Set the progress on the range slider
 */
function setRangeSliderProgress(el) {
    el.style.setProperty('--value', el.value);
    el.style.setProperty('--min', el.min == '' ? '0' : el.min);
    el.style.setProperty('--max', el.max == '' ? '100' : el.max);
    el.addEventListener('input', () => el.style.setProperty('--value', el.value));
}